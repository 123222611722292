import React, { useState } from 'react';
import { AiOutlineMenu, AiOutlineSearch, AiOutlineClose, AiFillHome, AiOutlineLogout } from "react-icons/ai";
import { FaPhone, FaInfoCircle } from "react-icons/fa";
import { SlUser, SlLogin } from "react-icons/sl";
import { Link, useNavigate } from 'react-router-dom';
import { useContextSelector } from "use-context-selector";
import toast, { Toaster } from "react-hot-toast";
import { StateContext } from "../contexts/StateContext";
import { ADMIN, PUPIL, ACCESS_TOKEN, ADMIN_TOKEN, SHOPADMIN, SHOPADMIN_TOKEN, USERNAMEWITHMAIL } from "../config/Config";
import { data } from '../data/data';

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const navigate = useNavigate();

    const isLoggedIn = useContextSelector(StateContext, (state) => state.isLoggedIn);
    const setRole = useContextSelector(StateContext, (state) => state.setRole);
    const role = useContextSelector(StateContext, (state) => state.role);
    const setIsLoggedIn = useContextSelector(StateContext, (state) => state.setIsLoggedIn);
    const loggedInUserEmail = useContextSelector(StateContext, (state) => state.userEmail);

    // Log the value of USERNAMEWITHMAIL to verify it's correct
    console.log("Logged-in user email:", localStorage.getItem(USERNAMEWITHMAIL));

    // Filter data based on logged-in user's shop email
    const filteredData = data.filter(item => {
        // Check if both values are defined before trimming and comparing
        if (localStorage.getItem(USERNAMEWITHMAIL) && item.shopEmail) {
            console.log("Logged-in user email:", localStorage.getItem(USERNAMEWITHMAIL));
            console.log("Comparing with shopEmail:", item.shopEmail);
    
            // Perform case-insensitive comparison
            return item.shopEmail.trim().toLowerCase() === localStorage.getItem(USERNAMEWITHMAIL).trim().toLowerCase();
        }
        return false; // Skip if either value is undefined
    });

    // Log the filtered data to check if it's correctly filtering
    console.log("Filtered data based on shopEmail:", filteredData);

    const logout = () => {
        localStorage.clear();
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(ADMIN_TOKEN);
        localStorage.removeItem(SHOPADMIN_TOKEN);
        setIsLoggedIn(false);
        setShowUser(false);
        setRole("");
        toast.success("Logout successfully");
        navigate("/");
    };

    const handleNavigation = (path) => {
        setNav(false); // Close the navbar
        navigate(path); // Navigate to the desired path
    };

    return (
        <div className="max-w-[1640px] mx-auto flex justify-between items-center p-4">
            <div className="flex items-center">
                <div onClick={() => setNav(!nav)} className="cursor-pointer">
                    <AiOutlineMenu size={30} />
                </div>
                <h1 className="text-xl sm:text-3xl lg:text-4xl px-2 text-blue-400">
                    My Kidai Virundhu <span className="font-bold text-red-600"></span>
                </h1>
                <div className="hidden lg:flex items-center bg-gray-200 rounded-full p-1 text-[14px]">
                    <p className="bg-black text-white rounded-full p-2">Delivery</p>
                    <p className="p-2">Pickup</p>
                </div>
            </div>
            <div className="bg-gray-200 rounded-full flex items-center px-2 w-[200px] sm:w-[400px] lg:w-[500px]">
                <AiOutlineSearch size={20} />
                <input className="bg-transparent p-2 w-full focus:outline-none" type="text" placeholder="Search foods" />
            </div>
            <div className='w-[350px] text-xl text-orange-500 hidden md:block'>
                <marquee behavior="scroll" direction="left">Welcome to our AyKaruppu Kidai Virundhu Restaurant, You can find all sorts of Indian Foods of your choice</marquee>
            </div>
            {nav ? <div onClick={() => setNav(!nav)} className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"></div> : ''}
            <div className={nav ? "fixed top-0 left-0 w-[300px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300"}>
                <AiOutlineClose onClick={() => setNav(!nav)} size={30} className="absolute right-4 top-4 cursor-pointer" />
                <h2 className="text-xl p-4">
                   AyKaruppu Kidai Virundhu <span className="font-bold"></span>
                </h2>
                <nav>
                    <ul className="flex flex-col p-4 text-gray-800">
                       
                        <li onClick={() => handleNavigation('/')} className="text-xl py-4 flex cursor-pointer"><AiFillHome size={25} className="mr-4" /> Home</li>
                        {isLoggedIn && (
                            <>
                                <li onClick={() => handleNavigation('/profile')} className="text-xl py-4 flex cursor-pointer"><SlUser size={25} className="mr-4" /> Profile</li>
                                {role === ADMIN && (
                                    <>
                                        <li onClick={() => handleNavigation('/admindashboard')} className="text-xl py-4 flex cursor-pointer"><SlLogin size={25} className="mr-4" /> Admin Dashboard</li>
                                        {/*<li onClick={() => handleNavigation('/shopdashboard')} className="text-xl py-4 flex cursor-pointer"><SlLogin size={25} className="mr-4" /> Shop Dashboard</li>*/}
                                        <li onClick={() => handleNavigation('/userdashboard')} className="text-xl py-4 flex cursor-pointer"><SlUser size={25} className="mr-4" /> Your Orders</li>
                                    </>
                                )}
                                {role === PUPIL && (
                                    <>
                                        <li onClick={() => handleNavigation('/userdashboard')} className="text-xl py-4 flex cursor-pointer"><SlLogin size={25} className="mr-4" /> Your Orders</li>
                                    </>
                                )}
                                {/* Check if the logged-in user has matching shopEmail */}
                                {filteredData.length > 0 && (
                                    <li onClick={() => handleNavigation('/ownshopdashboard')} className="text-xl py-4 flex cursor-pointer">
                                        <SlLogin size={25} className="mr-4" /> Own Shop Orders
                                    </li>
                                )}
                                {role === SHOPADMIN && (
                                    <>
                                        <li onClick={() => handleNavigation('/malligamessdashboard')} className="text-xl py-4 flex cursor-pointer"><SlLogin size={25} className="mr-4" /> Malliga Mess Dashboard</li>
                                        <li onClick={() => handleNavigation('/userdashboard')} className="text-xl py-4 flex cursor-pointer"><SlUser size={25} className="mr-4" /> Your Orders</li>
                                    </>
                                )}
                                <li onClick={logout} className="text-xl py-4 flex cursor-pointer"><AiOutlineLogout size={25} className="mr-4" /> Logout</li>
                            </>
                        )}
                        {!isLoggedIn && (
                            <>
                                <li onClick={() => handleNavigation('/signin')} className="text-xl py-4 flex cursor-pointer"><SlLogin size={25} className="mr-4" /> Sign In</li>
                                <li onClick={() => handleNavigation('/signup')} className="text-xl py-4 flex cursor-pointer"><SlUser size={25} className="mr-4" /> Sign Up</li>
                            </>
                        )}
                        <li onClick={() => handleNavigation('/contact')} className="text-xl py-4 flex"><FaPhone size={25} className="mr-4" /> Contact Us</li>
                        <li onClick={() => handleNavigation('/about')} className="text-xl py-4 flex cursor-pointer"><FaInfoCircle size={25} className="mr-4" /> About Us</li></ul>
                </nav>
            </div>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    error: {
                        duration: 6000,
                        style: {
                            background: "#FF4136",
                            color: "#fff",
                        },
                    },
                    success: {
                        duration: 6000,
                        style: {
                            background: "#008000",
                            color: "#fff",
                        },
                    },
                }}
            />
        </div>
    );
};

export default Navbar;
